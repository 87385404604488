import React, { useState, useEffect } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import logo from "./../images/pravi-logo.svg";
import "./FooterMenu.css";


function FooterMenu(props) {

    const menu = props.footerMenu ? props.footerMenu.menuItems.edges : [];

    console.log(menu)

    // useEffect(() => {
    //     if (document.querySelectorAll(".slash")[0]) {
    //         document.querySelectorAll(".slash")[0].style.display = "none";
    //     }
    // }, [])

    return (
        <div className="footer-container mx-auto p-20">

            <nav className=" m-auto flex flex-wrap lg:flex-no-wrap flex-col lg:flex-row lg:justify-around items-start pt-16 pb-8">
                <div className="flex flex-wrap justify-between items-start">
                    {menu.map((tab, index) => {
                        if (index === 0) {
                            return (
                                <React.Fragment key={`footer-l-${index}`}>
                                    <div className="flex flex-row flex-wrap justify-between w-full  lg:w-4/12 pb-20 lg:pb-8 lg:pb-0">
                                        <img className="m-auto" src={logo} alt="white-logo" style={{ width: "20rem" }} />
                                    </div>
                                    <div className="pb-16 md:pb-0 w-1/2 md:w-2/12">
                                        <div className="head-ul">{tab.node.label}</div>
                                        <ul className="footer-ul">
                                            {tab.node.childItems && tab.node.childItems.edges.map((innerLink, y) => (
                                                <li key={`foot-link-${y}`} className="py-2">
                                                    {console.log(innerLink.node.url.replace("http://", ""))}
                                                    <Link to={"/" + innerLink.node.url.replace("http://", "")}>
                                                        {innerLink.node.label}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="pb-16 md:pb-0 w-1/2 md:w-2/12">
                                        <div className="head-ul">{props.template.informationTitle}</div>
                                        <ul className="footer-ul">
                                            <li className="py-2">
                                                <a href={`mailto:${props.template.supportEmail}`}>
                                                    {props.template.supportEmail}
                                                </a>
                                            </li>
                                            <li className="py-2">
                                                <a href={`tel:${props.template.telephoneNumber}`}>
                                                    {props.template.telephoneNumber}
                                                </a>
                                            </li>
                                            <li>
                                                <a href={props.template.address.addressGoogleLink} target="_blank">
                                                    {props.template.address.addressLabel}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </React.Fragment>
                            )
                        }

                        return (
                            <div className="w-full md:w-4/12 text-3xl">
                                {tab.node.label}
                            </div>
                        )
                    })}
                </div>
            </nav>
        </div>
    )
}

export default FooterMenu;